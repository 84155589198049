import {
  Container,
  Text,
  Box,
  VStack,
  Flex,
  Image,
  HStack,
  Link,
} from "@chakra-ui/react";
import NavBar from "../home/components/NavBar";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareEnvelope } from "react-icons/fa6";

export const PrivacyPolicy = () => {
  return (
    <Container maxW="full" p={0} overflowX="hidden">
      <NavBar />
      <Container w="full" mt="100px" px={4}>
        <Text
          textAlign="center"
          fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
          fontWeight="bold"
          mb={8}
        >
          Privacy Policy
        </Text>
        <VStack align="stretch" spacing={6}>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              What information does the Application obtain and how is it used?
            </Text>
            <Text mt={2}>
              <Text fontWeight="bold">User Provided Information</Text>
              The Application acquires the information you supply when you
              download and register the Application. Registration with the
              Service Provider is not mandatory. However, bear in mind that you
              might not be able to utilize some of the features offered by the
              Application unless you register with them.
            </Text>
            <Text mt={2}>
              The Service Provider may also use the information you provided
              them to contact you from time to time to provide you with
              important information, required notices, and marketing promotions.
            </Text>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Automatically Collected Information
            </Text>
            <Text mt={2}>
              In addition, the Application may collect certain information
              automatically, including, but not limited to, the type of mobile
              device you use, your mobile device’s unique device ID, the IP
              address of your mobile device, your mobile operating system, the
              type of mobile Internet browsers you use, and information about
              the way you use the Application.
            </Text>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Does the Application collect precise real-time location
              information of the device?
            </Text>
            <Text mt={2}>
              This Application does not gather precise information about the
              location of your mobile device.
            </Text>
            <Text mt={2}>
              This Application collects your device's location, which helps the
              Service Provider determine your approximate geographical location
              and make use of it in the below ways:
            </Text>
            <Box pl={4} mt={2}>
              <Text as="li" listStyleType="disc">
                Geolocation Services: The Service Provider utilizes location
                data to provide features such as personalized content, relevant
                recommendations, and location-based services.
              </Text>
              <Text as="li" listStyleType="disc" mt={1}>
                Analytics and Improvements: Aggregated and anonymized location
                data helps the Service Provider to analyze user behavior,
                identify trends, and improve the overall performance and
                functionality of the Application.
              </Text>
              <Text as="li" listStyleType="disc" mt={1}>
                Third-Party Services: Periodically, the Service Provider may
                transmit anonymized location data to external services. These
                services assist them in enhancing the Application and optimizing
                their offerings.
              </Text>
            </Box>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="xl">
              Do third parties see and/or have access to information obtained by
              the Application?
            </Text>
            <Text mt={2}>
              Only aggregated, anonymized data is periodically transmitted to
              external services to aid the Service Provider in improving the
              Application and their service.
            </Text>
            <Text mt={2}>
              Please note that the Application utilizes third-party services
              that have their own Privacy Policy about handling data. Below are
              the links to the Privacy Policy of the third-party service
              providers used by the Application:
            </Text>
            <Box pl={4} mt={2}>
              <Text as="li" listStyleType="disc" mt={1}>
                <a href="https://aws.amazon.com/privacy/" isExternal>
                  Amazon Web Services
                </a>
              </Text>
              <Text as="li" listStyleType="disc">
                <a
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Play Services
                </a>
              </Text>
              <Text as="li" listStyleType="disc" mt={1}>
                <a
                  href="https://expo.io/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Expo
                </a>
              </Text>
              <Text as="li" listStyleType="disc" mt={1}>
                <a
                  href="https://sentry.io/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sentry
                </a>
              </Text>
              <Text as="li" listStyleType="disc" mt={1}>
                <a
                  href="https://clerk.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Clerk
                </a>
              </Text>
            </Box>
          </Box>
        </VStack>
      </Container>
      <Container
        marginTop={20}
        maxW="full"
        h={{ base: "300px" }}
        id="footer"
        backgroundColor="rgba(244, 190, 29, 0.15)"
        borderColor="oai.yellow"
        borderTopWidth="1.5px"
      >
        <Container maxW="1400px" py="20px" h="full">
          <Flex maxW="full" h="full" justify="space-between">
            <Flex
              maxW="200px"
              justify="space-between"
              direction="column"
              h="full"
            >
              <Box>
                <Image
                  src="oai-logo-long.svg"
                  alt="oai long logo"
                  maxW="200px"
                />
                <Text
                  fontSize={{ base: "l", sm: "l", md: "l", lg: "l" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="black"
                  mt="10px"
                >
                  AI-Driven monitoring systems for the construction industry
                </Text>
              </Box>
              <Box>
                <Text>© Opusense AI, 2024</Text>
              </Box>
            </Flex>
            <HStack alignSelf="flex-end" opacity={0.9}>
              <Link
                href="https://www.linkedin.com/company/opusense-ai/"
                isExternal
              >
                <FaLinkedin size="30px" color="oai.darkgrey" />
              </Link>
              <Link href="mailto:hello@opusense.com">
                <FaSquareEnvelope size="30px" color="oai.darkgrey" />
              </Link>
            </HStack>
          </Flex>
        </Container>
      </Container>
    </Container>
  );
};
