import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Importing all pages
import Login from "./pages/login/login"; // Importing the Login component
import Register from "./pages/register/register"; // Importing the Login component
import Forgotpw from "./pages/forgotpw/forgotpw"; // Importing the Login component
import MainPage from "./pages/main/main";
import Home from "./pages/home/home";
import DeviceRegistration from "./pages/deviceRegistration/deviceRegistration";
import DeviceRegistrationSuccess from "./pages/deviceRegistration/deviceRegistrationSuccess";
import DeviceManager from "./pages/deviceManager/deviceManager";

// Importing styling sheet
import "@aws-amplify/ui-react/styles.css";

import { AuthProvider, useAuth } from "./AuthContext";
import AccountManager from "./pages/accountManager/accountManager";
import { PrivacyPolicy } from "./pages/privacyPolicy/privacyPolicy";
import { TermsOfService } from "./pages/termsOfServices/termsOfServices";

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

function App() {
  const { isAuthenticated, isLoading } = useAuth();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setAuthChecked(true);
    }
  }, [isLoading]);

  if (!authChecked) {
    return <div></div>; // Or a loading spinner component
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {isAuthenticated ? (
          // Routes for authenticated users
          <>
            <Route path="/main" element={<MainPage />} />
            <Route
              path="/device_registration"
              element={<DeviceRegistration />}
            />
            <Route
              path="/device_registration_success"
              element={<DeviceRegistrationSuccess />}
            />
            <Route path="/account" element={<AccountManager />} />
            <Route path="/device_manager" element={<DeviceManager />} />
            <Route path="*" element={<Navigate to="/main" />} />
          </>
        ) : (
          // Add more authenticated routes here
          // Routes for non-authenticated users
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<Forgotpw />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default AppWrapper; // Exporting the App component
