import {
  Container,
  Text,
  Box,
  Flex,
  Image,
  HStack,
  Link,
} from "@chakra-ui/react";
import NavBar from "../home/components/NavBar";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareEnvelope } from "react-icons/fa6";

export const TermsOfService = () => {
  return (
    <Container maxW="full" p={0} overflowX="hidden" h="100vh">
      <NavBar />
      <Flex
        w="full"
        h="calc(100vh - 100px)" // Adjust height to account for NavBar
        align="center"
        direction="column"
        mt="100px"
      >
        <Text
          textAlign="center"
          fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
          fontWeight="bold"
          mb={8}
        >
          Terms of Service
        </Text>
      </Flex>
      <Container
        marginTop="auto"
        maxW="full"
        h={{ base: "300px" }}
        id="footer"
        backgroundColor="rgba(244, 190, 29, 0.15)"
        borderColor="oai.yellow"
        borderTopWidth="1.5px"
      >
        <Container maxW="1400px" py="20px" h="full">
          <Flex maxW="full" h="full" justify="space-between">
            <Flex
              maxW="200px"
              justify="space-between"
              direction="column"
              h="full"
            >
              <Box>
                <Image
                  src="oai-logo-long.svg"
                  alt="oai long logo"
                  maxW="200px"
                />
                <Text
                  fontSize={{ base: "l", sm: "l", md: "l", lg: "l" }}
                  fontWeight="normal"
                  fontFamily="niramit"
                  color="black"
                  mt="10px"
                >
                  AI-Driven monitoring systems for the construction industry
                </Text>
              </Box>
              <Box>
                <Text>© Opusense AI, 2024</Text>
              </Box>
            </Flex>
            <HStack alignSelf="flex-end" opacity={0.9}>
              <Link
                href="https://www.linkedin.com/company/opusense-ai/"
                isExternal
              >
                <FaLinkedin size="30px" color="oai.darkgrey" />
              </Link>
              <Link href="mailto:hello@opusense.com">
                <FaSquareEnvelope size="30px" color="oai.darkgrey" />
              </Link>
            </HStack>
          </Flex>
        </Container>
      </Container>
    </Container>
  );
};
